//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100;
    border-color: $gray-100;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
    min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: $gray-300;
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: $gray-100;
        padding: 10px;
        border: 1px solid $gray-300;
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Input
input {
    &:focus {
        outline: none;
    }
}

// Custom - form-group-hint
.form-control-with-hint {
    position: relative;
    .form-control {
        padding-right: 50px !important;
    }
    .form-control-feedback {
        position: absolute;
        top: $input-border-width + 1;
        bottom: $input-border-width + 1;
        right: $input-padding-x * 0.75;
        display: flex;
        align-items: center;
        background: transparent;
        font-size: 0.75rem;
        font-weight: $font-weight-semibold;
        color: lighten($input-color, 15%);
    }
}

// .form-label {
//     font-weight: $font-weight-medium !important;
// }

.form-check {
    .form-check-input:checked ~ .form-label {
        &::after {
            background-image: none;
            font-family: 'unicons';
            content: '\e8d9';
            font-size: 1.25rem;
            top: -0.185rem;
            left: -1.7rem;
            color: #ffffff;
        }
    }
}

.color55 {
    color: #555555;
}

.rbt-aux {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
}

.t-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.rbt-menu {
    box-shadow: 0px 1px 7px rgb(0 0 0 / 15%) !important;
    z-index: 800 !important;
}

.label-length-data {
    padding-top: 5px;
    padding-bottom: 5px;
    width: max-content;
    border-radius: 5px;
    background: #e8edff;
    color: #0b7f8e;
}

.color-y {
    color: #f78216;
}

.react-datepicker-popper {
    z-index: 9 !important;
}

.text-color-blue {
    color: #0b7f8e;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}
.rbt-token {
    color: #0b7f8e !important;
}

.color555 {
    color: #555555;
}

.text-align-right {
    text-align: right !important;
}

.fs-10 {
    font-size: 10px !important;
}
.border-blue {
    border-color: #0b7f8e;
}

.label-perusahaan {
    width: fit-content;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    background: #c1c1c1;
    color: white;
    font-weight: bold;

    padding: 0px 5px;
    margin-right: 10px;
}

.link {
    cursor: pointer;
    color: blue;
    &:hover {
        opacity: 0.8;
    }
}

.blink {
    animation: blinkMe 2s linear infinite;
}

.text-load-sensor {
    position: relative;
    top: 95px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.x-icon {
    position: absolute;
    right: 5px;
    top: 20px;
}
@keyframes blinkMe {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.highlightOption {
    background: none !important;
    color: none !important;
}

.input-search-iot {
    max-width: 300px;
    float: right;
    position: relative;
    top: 25px;
}

.t-main-color {
    color: #0b7f8e;
}

.box-tab {
    border: 1px solid #0b7f8e;
    height: 40px;
    border-radius: 100px;
}

.box-tab-active {
    border: 1px solid #0b7f8e;
    height: 40px;
    border-radius: 100px;
    background: #0b7f8e;
    color: white;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-tab-non-active {
    color: #0b7f8e;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.box-tab2 {
    border: 1px solid #0b7f8e;
    height: 30px;
    border-radius: 100px;
    max-width: 210px;
}

.box-tab-active2 {
    border: 1px solid #0b7f8e;
    height: 30px;
    border-radius: 100px;
    background: #0b7f8e;
    color: white;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-tab-non-active2 {
    color: #0b7f8e;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

// RHPP
.box-tab3 {
    border: 1px solid #0b7f8e;
    height: 40px;
    border-radius: 100px;
    max-width: 400px;
}

.box-tab3-active {
    border: 1px solid #0b7f8e;
    height: 40px;
    border-radius: 100px;
    background: #0b7f8e;
    color: white;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-tab3-non-active {
    color: #0b7f8e;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.txt-circle1 {
    position: absolute;
    bottom: 16px;
    right: 74px;
}

.txt-circle2 {
    position: absolute;
    bottom: 33px;
    right: 115px;
}

.txt-circle3 {
    position: absolute;
    bottom: 73px;
    right: 127px;
}

.txt-circle4 {
    position: absolute;
    top: 33px;
    right: 111px;
}

.txt-circle5 {
    position: absolute;
    top: 16px;
    right: 73px;
}

.txt-circle6 {
    position: absolute;
    top: 33px;
    right: 32px;
}

.txt-circle7 {
    position: absolute;
    top: 73px;
    right: 16px;
}

.txt-celcius {
    position: absolute;
    top: 50px;
}

.fs-8 {
    font-size: 8px !important;
}

.color33333 {
    color: #333333;
}

.color77777 {
    color: #777777;
}

.bgcolorf4f4f4 {
    background-color: #f4f4f4;
}

.card {
    border-radius: 10px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.pos_blink {
    position: absolute;
    right: 85px;
    bottom: 29px;
}

.recharts-text {
    font-size: 12px;
}

.text-format {
    position: relative;
    bottom: 75px;
    /* right: -8px; */
}

.custom-form {
    .rbt-input-multi {
        border: 1px solid #e3e5e8;
        border-radius: 8px;
    }
}

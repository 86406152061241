// overriding popup
.leaflet-container {
    font-family: $font-family-base;
    @include font-size($font-size-base);
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $body-color;

    a.leaflet-popup-close-button {
        color: #fff;
        font-size: 1.5rem;
        margin: 7px;
    }
}

.custom-map-marker-popup {
    .leaflet-popup-content-wrapper {
        padding: 0px !important;

        .leaflet-popup-content {
            margin: 0px !important;

            p {
                margin: inherit;
            }
        }
    }
}

// custom marker
.custom-map-marker {
    font-size: 0.75rem;
    font-weight: $font-weight-semibold;
    background-color: $white;
    color: $body-color;
    border-color: $white;
    box-shadow: 0 1rem 3rem rgba($black, 0.275);

    &:before {
        box-shadow: 0 1rem 3rem rgba($black, 0.275);
    }

    &.active {
        background-color: $primary;
        border-color: $primary;
        color: $white;

        &:before {
            border-top-color: $primary;
        }
    }
}

.custom-marker-icon {
    text-align: center; /* Horizontally center the text (icon) */
    line-height: 28px; /* Vertically center the text (icon) */
}

// .leaflet-control-container {
//     .leaflet-control-zoom.leaflet-bar a {
//         background-color: $primary;
//         color: white;

//         &:hover {
//             background-color: darken($primary, 2%);
//         }
//     }

//     .leaflet-control-zoom {
//         .leaflet-control-zoom-in {
//             border: none;
//             border-radius: 2px 2px;
//             margin-bottom: 4px;
//         }

//         .leaflet-control-zoom-out {
//             border-radius: 2px 2px;
//         }
//     }

//     .leaflet-top.leaflet-right {
//         display: flex;
//         flex-direction: column;
//         gap: 16px;
//     }

//     .leaflet-bar .fullscreen-icon {
//         filter: invert(1);
//         mix-blend-mode: multiply;
//     }

// }

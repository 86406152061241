.content {
    padding-top: 7rem !important;
    padding-bottom: 1.5rem !important;
}

.btn:hover .svg-hover-white {
    filter: brightness(0) invert(1);
}

.typehead-primary .rbt-input-main {
    border-color: $primary;
}

@media (max-width: 992px) {
    .container-responsive {
        padding-top: 90px !important;
    }

    .overflow-x-responsive {
        overflow-y: hidden;
        overflow-x: auto;
    }
}

.gray-hover:hover {
    background-color: rgb(248, 248, 248);
}

.table-layout-fixed {
    table-layout: fixed;
}

.select-none {
    user-select: none;
}

.plasma-menu {
    .plasma-menu {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    button {
        padding: 0.3rem 1.2rem;
        text-align: left;
        opacity: 0.75;
    }

    button.active {
        background: #012c32;
        color: white;
        font-weight: 500;
        position: relative;
        opacity: 1;
    }

    button.active::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 10px;
        background-color: #0b7f8e;
    }

    button.active:hover {
        opacity: 90%;
    }

    button:hover {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .plasma-menu {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
}

.accordion-button:not(.collapsed) {
    background-color: white;
    color: var(--bs-accordion-btn-color);
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

.input-group-text.input-group-text-plain {
    background: white;
    color: $gray-500;
    user-select: none;
}

.input-group:has(.input-group-text-plain)
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group:has(.input-group-text-plain)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:has(.input-group-text-plain) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:has(.input-group-text-plain) > .form-floating:not(:last-child) > .form-control,
.input-group:has(.input-group-text-plain) > .form-floating:not(:last-child) > .form-select {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group.has-validation:has(.is-invalid):has(.input-group-text-plain)
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-color: $danger;
}

.input-group.has-validation:has(.is-warning):has(.input-group-text-plain)
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-color: $warning;
}

.input-group.has-validation:has(.is-invalid):has(.input-group-text-plain)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation:has(.is-invalid):has(.input-group-text-plain) > .dropdown-toggle:nth-last-child(n + 3),
.input-group.has-validation:has(.is-invalid):has(.input-group-text-plain)
    > .form-floating:not(:last-child)
    > .form-control,
.input-group.has-validation:has(.is-invalid):has(.input-group-text-plain)
    > .form-floating:not(:last-child)
    > .form-select {
    border-color: $danger;
}

.input-group.has-validation:has(.is-warning):has(.input-group-text-plain)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation:has(.is-warning):has(.input-group-text-plain) > .dropdown-toggle:nth-last-child(n + 3),
.input-group.has-validation:has(.is-warning):has(.input-group-text-plain)
    > .form-floating:not(:last-child)
    > .form-control,
.input-group.has-validation:has(.is-warning):has(.input-group-text-plain)
    > .form-floating:not(:last-child)
    > .form-select {
    border-color: $warning;
}

.form-control.is-warning {
    background-image: url('../../images/icons/warning-circle.svg') !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.28125rem) center;
}

.form-control:focus ~ .input-group-text-plain {
    border-color: rgba(0, 0, 0, 0.25);
}

.input-group-text-plain:has(+ .form-control:focus) {
    border-color: rgba(0, 0, 0, 0.25);
}

.input-group:has(.input-group-text-plain) > .form-control:disabled {
    background-color: $gray-200;
}

.input-group:has(.input-group-text-plain):has(.form-control:disabled) > .input-group-text-plain {
    background-color: $gray-200;
}

.input-group:has(.input-group-text-plain) > .form-control:disabled {
    background-color: $gray-200;
}

.input-group:has(.input-group-text-plain):has(.form-control:disabled) > .input-group-text-plain {
    background-color: $gray-200;
}

.form-check-input:disabled {
    background-color: $gray-400;
}

.indicator {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: -5px;
        right: -5px;
        width: 10px;
        height: 10px;
        border-radius: 5rem;
        background-color: $danger;
    }
}

//
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
    height: 5px;
}

// Progress height medium
.progress-md {
    height: 8px;
}

// Progress height large
.progress-lg {
    height: 12px;
}

// Progress height extra large
.progress-xl {
    height: 15px;
}

// Progress height dubble extra large
.progress-xxl {
    height: 25px;
}

/* Progressbar Vertical */
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
        width: 100%;
    }

    &.progress-xl {
        width: 15px;
    }

    &.progress-lg {
        width: 12px;
    }

    &.progress-md {
        width: 8px;
    }

    &.progress-sm {
        width: 5px;
    }
}

.progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;

    .progress-bar {
        width: 100%;
        bottom: 0;
        position: absolute;
    }

    &.progress-xl {
        width: 15px;
    }

    &.progress-lg {
        width: 12px;
    }

    &.progress-md {
        width: 8px;
    }

    &.progress-sm {
        width: 5px;
    }
}

// Progress with percentage

.progress-w-percent {
    min-height: 20px;
    margin-bottom: 20px;

    .progress {
        width: 80%;
        float: left;
        margin-top: 8px;
    }

    .progress-value {
        width: 20%;
        float: right;
        text-align: right;
        line-height: 20px;
    }
}

.div-loading {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
}

.loading-container {
    position: relative;
    width: 110px;
    height: 110px;
    margin: auto;
}
.loading-container .item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #0b7f8e;
    border-radius: 50%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation: spin 2s ease infinite;
}
.loading-container .item:nth-child(1) {
    width: 50px;
    height: 50px;
}
.loading-container .item:nth-child(2) {
    width: 70px;
    height: 70px;
    animation-delay: 0.1s;
}
.loading-container .item:nth-child(3) {
    width: 90px;
    height: 90px;
    animation-delay: 0.2s;
}
.loading-container .item:nth-child(4) {
    width: 110px;
    height: 110px;
    animation-delay: 0.3s;
}
@keyframes spin {
    50% {
        transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

@keyframes growProgressBar {
    0%,
    33% {
        --pgPercentage: 0;
    }
    100% {
        --pgPercentage: var(--value);
    }
}

@property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

div[role='progressbar'] {
    --size: 12rem;
    --fg: #369;
    --bg: #def;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    font-family: Helvetica, Arial, sans-serif;
    font-size: calc(var(--size) / 5);
    color: var(--fg);
}

div[role='progressbar']::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
}

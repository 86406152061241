#ppic {
    .select-ap {
        padding: 10px 12px;
        border-radius: 8px;
        color: #202224;
        font-weight: 400;
        font-size: 14px;
        border: 1px solid #e3e5e8;
        width: 100%;
        cursor: pointer;
    }

    .dates {
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .range-date {
            width: 212px;
            flex: none;
            text-align: center;
            padding: 8px 24px;
            border: 1px solid #e0e3e6;
            border-left: 0.5px solid #e0e3e6;
            border-right: 0.5px solid #e0e3e6;
            &:first-child {
                border-left: none;
            }
            &:last-child {
                border-right: none;
            }
        }
    }

    .react-datepicker-popper {
        .react-datepicker {
            border: 1px solid #d0d5da;
            border-radius: 12px;
            .react-datepicker__triangle {
                display: none;
            }
            .react-datepicker__navigation {
                width: 24px;
                height: 24px;
                top: 24px;
                &.react-datepicker__navigation--previous {
                    left: 15px;
                    .react-datepicker__navigation-icon--previous {
                        &::before {
                            border-color: #202224;
                            border-width: 1.5px 1.5px 0 0;
                        }
                    }
                }
                &.react-datepicker__navigation--next {
                    right: 15px;
                    .react-datepicker__navigation-icon--next {
                        &::before {
                            border-color: #202224;
                            border-width: 1.5px 1.5px 0 0;
                        }
                    }
                }
            }
            .react-datepicker__month-container {
                padding: 16px;
                .react-datepicker__header {
                    background: transparent;
                    padding: 0;
                    border: none;
                    .react-datepicker__current-month {
                        background: #eceef0;
                        border: 1px solid #d0d5da;
                        border-radius: 6px;
                        font-weight: 500;
                        font-size: 18px;
                        margin-bottom: 8px;
                    }
                    .react-datepicker__day-names {
                        .react-datepicker__day-name {
                            font-weight: 400;
                            color: #202224;
                            width: 2.5rem;
                        }
                    }
                }
                .react-datepicker__month {
                    margin: 0;
                    margin-top: 8px;
                    .react-datepicker__week {
                        font-weight: 500;
                        color: #202224;
                        .react-datepicker__day {
                            color: #202224;
                            font-weight: 500;
                            margin: 6px 2px;
                            width: 2.5rem;
                        }
                        .react-datepicker__day--selected,
                        .react-datepicker__day--range-end {
                            background: #0466c8 !important;
                            border-radius: 4px !important;
                            color: #ffffff !important;
                        }
                        .react-datepicker__day--in-range {
                            background: #eaf2fa;
                            border-radius: 4px;
                            color: #202224;
                        }
                        .react-datepicker__day--in-selecting-range {
                            background: #eaf2fa;
                            color: #202224;
                        }
                        .react-datepicker__day--selecting-range-end {
                            background: #0466c8 !important;
                            border-radius: 4px !important;
                            color: #ffffff !important;
                        }
                    }
                }
            }
        }
    }

    .pagination {
        margin-bottom: 0;
        .page-item {
            &:first-child {
                .page-link {
                    border: none;
                    margin-right: 16px;
                    background-color: transparent;
                }
            }
            &:last-child {
                .page-link {
                    border: none;
                    margin-left: 16px;
                    background-color: transparent;
                }
            }
            .page-link {
                border-radius: 0;
                padding: 4px 12px;
            }
        }
    }
}
